export default {
  BUTTON_PRIMARY_CLASS: 'btn btn-default',
  PRODUCT_REPEATER_CLASS: 'productBlockContainer columns-3 clearfix',

  FACET_DIALOG_TITLE: 'Filter Options',

  SEARCH_BOX_INPUT_CLASS: 'search-text',
  SEARCH_BOX_BUTTON_CLASS: 'search-btn',
  SEARCH_BOX_FORM_CLASS: 'form-field',
};
